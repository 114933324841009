<template>
    <div class="ware-tagging">
        <div
            v-if="folders && !isEmpty(folders)"
            class="row folders"
        >
            <template v-for="folder in folders">
                <div
                    v-if="folder.labels.length > 1"
                    :key="`option-${folder.id}`"
                    class="col-6 folder"
                >
                    <y-panel
                        :title="folder.title"
                        size="sm"
                    >
                        <y-loading
                            :active="$wait.is('folders-loading')"
                            height="250px"
                        >
                            <div class="tags-select">
                                <y-form-field
                                    v-for="label in folder.labels"
                                    :id="`label-${label.id}`"
                                    :key="`label-${label.id}`"
                                    v-model="model.labels[label.id]"
                                    type="checkbox"
                                    :label="label.title"
                                    :name="`label-${label.id}`"
                                />
                            </div>
                        </y-loading>
                    </y-panel>
                </div>
            </template>
        </div>
        <y-empty-state
            v-else
            no-image
            height="230"
            :message="$t('market.product_tagging.empty_option')"
        />
    </div>
</template>

<script>

    import YEmptyState from '@deps/EmptyState';
    import WareTabMixin from '@/modules/market/mixins/WareTabMixin';

    export default {

        name: 'WareTabTagging',

        components: {
            YEmptyState,
        },

        mixins: [WareTabMixin],

        computed: {
            /**
             * Fetch Folders
             */
            folders() {
                return this.element.labels;
            },
        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.createLabels(this.model.labels);
        },

        methods: {
            /**
             * Create Model for Labels in edit mode
             *
             * @param items
             */
            createLabels(items) {
                const checked = {};
                if (!this.isEmpty(items)) {
                    Object.entries(items).forEach((key) => {
                        this.$set(checked, key[1], 1);
                    });
                    this.model.labels = checked;
                }
            },
        },

    };

</script>
