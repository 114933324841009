<template>
    <div>
        <!-- Page Head -->
        <y-page-head
            v-if="!$wait.is('loading-page')"
            icon="md-package-variant"
            :title="title"
            :desc="subtitle"
        >
            <template slot="actions">
                <y-button
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />

                <y-button
                    tag="router-link"
                    :to="{ name: 'market-wares-create' }"
                    class="color-blue"
                    disabled-on="submitting-form"
                >
                    {{ $t('button.add_ware') }}
                </y-button>

                <y-button
                    tag="router-link"
                    :to="editProductLink"
                >
                    {{ $t('button.back_to_product') }}
                </y-button>
            </template>
        </y-page-head>
        <!-- Page Head -->

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <main :class="[{disabled: $wait.is('submitting-form')}]">
                <draggable
                    v-if="wares && wares.length"
                    v-bind="dragOptions"
                    ref="Draggable"
                    handle=".slide-item header .md-menu"
                    class="m0 p0"
                    tag="ul"
                    :list="wares"
                    :group="{ name: 'g-wares' }"
                    @end="saveSort"
                >
                    <y-ware-item
                        v-for="ware in wares"
                        :key="`ware-item-${ware.id}`"
                        :item="ware"
                        :permissions="permissions"
                        :has-inventory="hasInventory"
                        :has-price="hasPrice"
                        @delete="reFetch"
                    />
                </draggable>

                <!-- Empty state -->
                <y-empty-state
                    v-else
                    :message="$t('market.wares.empty')"
                />
            </main>
        </y-loading>

        <y-delete-modal
            ref="DeleteModal"
            model="market-ware"
            :type="$t('market.wares.type_delete')"
            @done="reFetch"
        />
    </div>
</template>

<script>

    import PageMixin from '@/mixins/Page';

    import Draggable from 'vuedraggable';

    import YEmptyState from '@deps/EmptyState';
    import YWareItem from '@/modules/market/components/wares/WareItem';
    import YDeleteModal from '@deps/DeleteModal';

    export default {
        name: 'MarketWaresManage',

        components: {
            Draggable,
            YEmptyState,
            YWareItem,
            YDeleteModal,
        },

        mixins: [PageMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('market.wares.manage'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                hasInventory: null,
                hasPrice    : null,
                permissions : {
                    can_edit   : true,
                    can_trash  : true,
                    can_restore: true,
                    can_delete : true,
                },

                product: null,

                wares: [
                    {
                        id    : 'WxfWV',
                        title : 'سلام',
                        active: true,
                    },
                ],
            };
        },

        computed: {

            /**
             * Return title
             */
            title() {
                return this.$t('market.wares.manage');
            },

            /**
             * Return page subtitle
             */
            subtitle() {
                return this.get(this.product, 'title');
            },

            /**
             * @inheritDoc
             */
            dragOptions() {
                return {
                    animation : 200,
                    ghostClass: 'ghost',
                };
            },

            /**
             * Edit product link
             */
            editProductLink() {
                return {
                    name  : 'market-product-edit',
                    params: {
                        type: this.$route.params.type,
                        id  : this.$route.params.id,
                    },
                };
            },

        },

        methods: {

            /**
             * Fetch content type form and value
             */
            fetch() {
                const params = {
                    id       : this.$route.params.id,
                    including: ['currency_title', 'deleted_at', 'deleted_by', 'full_title',
                                'has_tax', 'original_price', 'purchase_price_average', 'sale_count', 'sale_price',
                                'sale_price_expires_at', 'supply_type', 'title', 'unit_title', 'current_inventory',
                                'sale_price_average', 'is_available', 'original_price_with_tax', 'sale_price_with_tax'],
                };
                return this.$services.Market.productWares(params).then((response) => {
                    this.product = response.data.metadata.product;
                    this.permissions = response.data.metadata.permissions;
                    this.wares = response.data.results;
                    this.hasInventory = response.data.metadata.has_inventory;
                    this.hasPrice = response.data.metadata.has_price;
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Save sort
             */
            saveSort() {
                const items = this.wares.map((ware) => ware.id);
                const params = {
                    product_id: this.$route.params.id,
                    items,
                };
                return this.$services.Market.waresSaveOrder(params).then(() => {
                    this.$toast.success(this.$t('market.wares.sort'));
                }).catch((error) => {
                    this.handleError(error);
                });
            },

        },

    };
</script>
