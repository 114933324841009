<template>
    <y-panel
        class="campaigns-editor-amount"
        :title="$t('campaigns.editor.amount.panel')"
    >
        <y-form-field
            v-model="model._amount"
            type="radio"
            class="campaigns-editor__radio"
            name="campaigns.amount.radio"
            :options="radioOptions"
            inline
        />

        <!-- Amount Percent -->
        <y-form-field
            v-if="model._amount === 'applicable_percent'"
            v-model="model.applicable_percent"
            type="number"
            name="campaigns.amount.percent_number"
            format="number"
            validation="required"
            max="100"
            min="0"
        />

        <!-- Amount Fee per Currency -->
        <div v-if="model._amount === 'applicable_amounts'">
            <y-form-cloner
                v-model="model.applicable_amounts"
                :max="metadata.currencies.length"
                :children="formFields"
                no-label
            />
        </div>
    </y-panel>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';
    import { YFormField, YFormCloner } from '@deps';

    export default {
        name: 'CampaignsAmountPanel',

        components: {
            YFormField,
            YFormCloner,
        },

        props: {
            /**
             * Metadata of the Editor
             */
            metadata: Object,

            /**
             * Campaign editor model
             */
            value: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},
                
                radioOptions: {
                    applicable_percent: this.$t('campaigns.editor.amount.percent'),
                    applicable_amounts: this.$t('campaigns.editor.amount.fee'),
                },
            };
        },

        computed: {
            /**
             * Return Array of currency Options
             */
            currencyOptions() {
                return this.metadata && this.metadata.currencies ? this.metadata.currencies.map((currency) => ({
                    label: currency.label,
                    value: currency.value,
                })) : [];
            },

            /**
             * Return the default currency
             */
            defaultCurrency() {
                return this.metadata && this.metadata.default_currency ? this.metadata.default_currency : null;
            },

            /**
             * Return Array of form elements required to render cloner rows
             */
            formFields() {
                return [
                    {
                        component_name: 'select',
                        dir           : 'auto',
                        name          : 'currency',
                        label         : this.$t('campaigns.editor.amount.currency'),
                        placeholder   : this.$t('campaigns.editor.amount.currency'),
                        id            : `select-${generateId()}`,
                        options       : this.currencyOptions,
                        class         : 'col-12 col-sm-6',
                        validation    : 'required',
                    },
                    {
                        component_name: 'number',
                        dir           : 'auto',
                        name          : 'amount',
                        format        : 'separated',
                        label         : this.$t('campaigns.editor.amount.fee_number'),
                        id            : `number-${generateId()}`,
                        class         : 'col-12 col-sm-6',
                        validation    : 'required',
                    },
                ];
            },
        },

        watch: {
            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

            'model._amount': {
                /**
                 * Add default currency to the cloner
                 * Reset applicable values on change type 
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    // Reset applicable values on change type
                    // To prevent sending 2 type at the same time on save
                    if (oldVal !== null && oldVal !== newVal && (!this.model.applicable_amounts || !this.model.applicable_amounts.length)) {
                        this.addDefaultCurrency();
                    }
                },
                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            if (this.model.applicable_percent) {
                this.$set(this.model, '_amount', 'applicable_percent');
            } else if (this.model.applicable_amounts && this.model.applicable_amounts.length) {
                this.$set(this.model, '_amount', 'applicable_amounts');
            } else {
                this.$set(this.model, '_amount', 'applicable_percent');
            }
        },

        methods: {
            
            /**
             * Add default currency to the cloner
             */
            addDefaultCurrency() {
                if (this.defaultCurrency && this.model._amount === 'applicable_amounts') {
                    this.model.applicable_amounts = [{
                        currency: this.defaultCurrency,
                        amount  : '',
                    }];
                }
            },
        },
    };

</script>
