<template>
    <div>
        <y-page-head
            :title="$t('market.settings', [title])"
            icon="md-cart-arrow-right"
            :class="[{disabled: $wait.is('loading-page')}]"
        >
            <template slot="actions">
                <y-button
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />

                <!-- Save -->
                <y-button
                    color="blue"
                    form="main-form"
                    loading-on="submitting-form"
                >
                    {{ $t('button.save') }}
                </y-button>
            </template>
        </y-page-head>

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <main>
                <form
                    id="main-form"
                    @submit.prevent="submit(save)"
                >
                    <div
                        v-if="panels && panels.length"
                        class="row"
                    >
                        <div
                            v-for="(col, i) in panels"
                            :key="`form-col-${i}`"
                            class="col-sm-6 sales-settings-panel-actions"
                        >
                            <y-panel
                                v-for="(panel, j) in col"
                                :key="`form-panel-${j}`"
                                :title="panel.title"
                            >
                                <template slot="functions">
                                    <y-form :params="panel.buttons" />
                                </template>
                                <y-form
                                    v-model="model"
                                    :params="panel.form"
                                />
                            </y-panel>
                        </div>
                    </div>
                </form>
            </main>
        </y-loading>
    </div>
</template>

<script>

    // Mixins
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';
    import GlobalMixin from '@/mixins/Global';

    // Components
    import { YForm } from '@deps';

    export default {

        name: 'CartSettings',

        components: {
            YForm,
        },

        mixins: [PageMixin, FormMixin, GlobalMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('market.settings', [this.title])),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('market.settings', [this.title]),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                panels: null,
                model : null,
                title : null,
            };
        },

        methods: {

            /**
             * Fetch grid
             *
             * @returns {*}
             */
            fetch() {
                const params = {
                    slug: this.$route.params.slug,
                };
                return this.$services.Market.settingsFetch(params).then((response) => {
                    this.panels = this.chunk(response.data.metadata.panels);
                    this.model = response.data.results;
                    this.title = this.get(response.data.metadata, 'content_type.title');
                    this.registerGlobalModals(response.data.metadata.modals);
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Save profile changes
             *
             * @returns {*}
             */
            save() {
                return this.$services.Market.settingsSave(this.model).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('market.settings_msg') }));
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Chunk array
             *
             * @param arr
             * @returns {Array}
             */
            chunk(arr) {
                const chunks = [arr];
                chunks[1] = chunks[0].splice(0, Math.floor(arr.length / 2));
                return chunks;
            },

        },

    };

</script>
