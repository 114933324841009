<template>
    <y-loading
        class="panel-grid-layout"
        :active="$wait.is('loading-page')"
        height="calc( 100vh - 121px )"
    >
        <y-page-head
            :title="pageHeadTitle"
            icon="md-basket"
        >
            <template slot="actions">
                <y-button
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="fetchSettings"
                />
                <y-button
                    color="green"
                    :loading-on="`button-loading-save`"
                    disabled-on="submitting-form"
                    @click="submit"
                >
                    {{ $t('button.save') }}
                </y-button>

                <y-button
                    v-if="ware_id && hasInventory"
                    @click.native.prevent="$refs.InventoryChangeModal.open(wareCombo)"
                >
                    {{ $t('button.inventory_change') }}
                </y-button>

                <y-button
                    tag="router-link"
                    :to="{ name: 'market-wares-list' }"
                >
                    {{ $t('button.back_to_wares') }}
                </y-button>
            </template>

            <!-- Tabs -->
            <template
                v-if="metadata && !isEmpty(metadata)"
                slot="tabs"
            >
                <template v-for="tab in tabs">
                    <a
                        v-if="tab.show"
                        :key="`tab-title-${tab.slug}`"
                        href="#"
                        :class="{active: activeTab === tab.slug}"
                        @click.prevent="changeTab(tab.slug)"
                    >
                        <i
                            v-if="tab.icon"
                            class="icon"
                            :class="[tab.icon]"
                        />
                        {{ $t(`ware.tabs.${tab.slug}`) }}
                    </a>
                </template>
            </template>
        </y-page-head>

        <main v-if="metadata && !isEmpty(metadata)">
            <div class="row ware-editor">
                <div class="col-8">
                    <template v-for="tab in tabs">
                        <div
                            v-show="activeTab === tab.slug"
                            :key="`tab-content-${tab.slug}`"
                            class="tab-content panel"
                        >
                            <!-- eslint-disable-next-line vue/require-component-is -->
                            <component
                                :is="tabComponent(tab.slug)"
                                v-model="model"
                                :element="metadata"
                            />
                        </div>
                    </template>
                </div>
                <div class="col-4">
                    <y-ware-setting
                        :model="model"
                        :metadata="metadata"
                        :product-title="productTitle"
                        :product-image="productImage"
                    />
                </div>
            </div>
        </main>

        <!-- Modals -->
        <y-inventory-change-modal ref="InventoryChangeModal" />
    </y-loading>
</template>

<script>

    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';
    import { capitalize } from '@nodes/helpers/string';

    import YWareSetting from '@/modules/market/components/WareForm/Setting';
    import TabBase from '@/modules/market/components/WareForm/Tabs/TabBase';
    import TabSizes from '@/modules/market/components/WareForm/Tabs/TabSizes';
    import TabInventory from '@/modules/market/components/WareForm/Tabs/TabInventory';
    import TabPayment from '@/modules/market/components/WareForm/Tabs/TabPayment';
    import TabTagging from '@/modules/market/components/WareForm/Tabs/TabTagging';
    import YInventoryChangeModal from '@/modules/market/components/InventoryChangeModal';

    const tabs = {
        TabBase,
        TabSizes,
        TabTagging,
        TabInventory,
        TabPayment,
    };

    export default {
        name: 'MarketWareEdit',

        components: {
            ...tabs,
            YWareSetting,
            YInventoryChangeModal,
        },
            
        mixins: [
            PageMixin,
            FormMixin,
        ],


        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('market-product-list', this.$t('breadcrumbs.market.page')),
                this.$bc(this.$t(`breadcrumbs.market.ware.${this.$route.params.wareid ? 'edit' : 'create'}`)),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t(`market.wares.${this.$route.params.wareid ? 'edit_title' : 'create'}`),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model    : {},
                metadata : null,
                form     : null,
                activeTab: 'base',
                wareCombo: {},
                ware_id  : this.$route.params.wareid,
            };
        },

        computed: {
            /**
             * Tabs
             */
            tabs() {
                return [
                    {
                        slug: 'base',
                        icon: 'md-card-text-outline',
                        show: true,
                    },
                    {
                        slug: 'sizes',
                        icon: 'md-ruler',
                        show: this.hasShipping,
                    },
                    {
                        slug: 'payment',
                        icon: 'md-wallet',
                        show: this.hasPrice,
                    },
                    {
                        slug: 'inventory',
                        icon: 'md-package-variant-closed',
                        show: this.hasInventory && !this.isEdit,
                    },
                    {
                        slug: 'tagging',
                        icon: 'md-package-variant-closed',
                        show: this.hasTagging,
                    },
                ];
            },

            /**
             * Check if is edit mode
             */
            isEdit() {
                return !!this.$route.params.wareid;
            },

            /**
             * Check if editor should has inventory tab
             */
            hasInventory() {
                return this.metadata && this.metadata.has_inventory;
            },

            /**
             * Check if editor should has Tagging tab
             */
            hasTagging() {
                return this.metadata.has_labels;
            },

            /**
             * Check if editor has shipping
             */
            hasShipping() {
                return this.metadata && this.metadata.approximate_sizes && this.metadata.approximate_sizes.length;
            },

            /**
             * Check if editor has price
             */
            hasPrice() {
                return this.metadata && this.metadata.has_price;
            },

            /**
             * Ware Title
             */
            wareTitle() {
                const { locale } = this.$i18n;
                if (this.model.titles[locale]) {
                    return this.model.titles[locale];
                }
                if (this.model.titles && !this.isEmpty(this.model.titles.length)) {
                    return this.model.titles[0];
                }
                return null;
            },

            /**
             * Page title
             */
            pageHeadTitle() {
                if (this.isEdit) {
                    return this.$t('market.wares.edit', [this.wareTitle]);
                }
                return this.$t('market.wares.create');
            },

            /**
             * Product Title
             */
            productTitle() {
                return this.metadata.product_title || '';
            },

            /**
             * Product Title
             */
            productImage() {
                return this.metadata.product_image || null;
            },
        },

        /**
         * Set default value for data if there are missing in the model
         */
        created() {
            // TabBase
            if (!this.model.titles) {
                this.model.titles = {};
            }
            if (!this.model.descriptions) {
                this.model.descriptions = {};
            }

            // TabInventory
            if (!this.model.unit_id) {
                this.$set(this.model, 'unit_id', this.get(this.metadata, 'units.0.value', ''));
            }
            if (!this.model.unique_wares) {
                this.$set(this.model, 'unique_wares', []);
            }
            if (!this.model.dependent_wares) {
                this.$set(this.model, 'dependent_wares', []);
            }

            // TabPayment
            if (!this.model.payment_periods_details) {
                this.$set(this.model, 'payment_periods_details', {});
            }

            // TabTagging
            if (!this.model.labels) {
                this.$set(this.model, 'labels', {});
            }
        },

        /**
         * Call Fetches
         */
        mounted() {
            this.fetchSettings();
        },

        methods: {
            /**
             * Return tab component name
             *
             * @param tab
             */
            tabComponent(tab) {
                return `Tab${capitalize(tab)}`;
            },

            /**
             * Change tab
             *
             * @param tab
             */
            changeTab(tab) {
                this.activeTab = tab;
            },

            /**
             * Check if object is empty
             *
             * @param obj
             */
            isEmpty(obj) { // eslint-disable-line consistent-return
                if ((typeof obj === 'object') && (obj !== null)) {
                    return (Object.entries(obj).length === 0 && obj.constructor === Object);
                }
            },

            /**
             * Fetch base data
             */
            fetchSettings() {
                this.$wait.start('loading-page');
                const params = {
                    post_id: this.$route.params.id,
                };
                if (this.isEdit) {
                    params.id = this.ware_id;
                }
                if (this.$route.query.clone) {
                    params.id = this.$route.query.clone;
                }
                this.$services.Market.wareFetch(params).then((response) => {
                    if (this.isEdit || this.$route.query.clone) {
                        this.$set(this, 'model', response.data.results);
                        this.$set(this, 'wareCombo', response.data.metadata.inventory_wares_combo);
                    }
                    this.model.unit_id = this.get(response.data.metadata, 'units.0.value');
                    this.set(this, 'metadata', response.data.metadata);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('loading-page');
                });
            },

            /**
             * Submit form
             */
            submit() {
                this.$wait.start('button-loading-save');
                const newModel = this.model;
                if (this.$route.query.clone) {
                    delete newModel.id;
                    if (this.metadata.ware_code_auto) {
                        delete newModel.code;
                    }
                }
                const params = {
                    product_id: this.$route.params.id,
                    ...newModel,
                };
                return this.$services.Market.wareSave(params).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('market.wares.type') }));
                    this.$router.push({ name: 'market-wares-list' });
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('button-loading-save');
                });
            },

        },
    };
</script>
