<template>
    <y-panel
        class="campaigns-editor-group"
        :title="$t('campaigns.editor.category.panel')"
    >
        <template slot="functions">
            <y-form-field
                v-if="typesOptions && typesOptions.length > 1"
                v-model="model.type_id"
                type="select"
                name="type"
                :placeholder="$t('campaigns.editor.category.type')"
                :options="typesOptions"
                no-label
                validation="required"
                @select="onTypeChange"
            />
        </template>

        <div class="row">
            <div class="col-12 col-sm-6">
                <y-panel
                    class="campaigns-editor-categories"
                    :title="$t('campaigns.editor.category.categories')"
                >
                    <template slot="functions">
                        <y-search-input
                            ref="categorySearchInput"
                            :disabled="$wait.is('fetch-product')"
                            :debounce-time="500"
                            @typing="onCategorySearchTyping"
                            @search="onCategorySearch"
                        />
                    </template>

                    <y-category-list
                        v-model="model"
                        :categories="categories"
                        @click="handleClick"
                    />
                </y-panel>
            </div>

            <div class="col-12 col-sm-6">
                <y-panel
                    class="campaigns-editor-products"
                    :title="$t('campaigns.editor.product.products')"
                >
                    <template slot="functions">
                        <y-search-input
                            ref="productSearchInput"
                            :disabled="!!selectedCategory || $wait.is('fetch-category')"
                            :debounce-time="500"
                            @typing="onProductSearchTyping"
                            @search="onProductSearch"
                        />
                    </template>
                    <div class="products-result-wrapper">
                        <div
                            v-if="selectedCategory"
                            class="product-title"
                        >
                            {{ $t('campaigns.editor.product.title.category_selected', [selectedCategory.title]) }}
                            <i
                                class="icon md-close"
                                @click="resetProducts"
                            />
                        </div>
                        <div
                            v-else-if="query.productSearch"
                            class="product-title"
                        >
                            {{ $t('campaigns.editor.product.title.search') }}
                        </div>
                        <div class="products-result">
                            <y-product-list
                                v-model="model"
                                :products="products"
                            />
                        </div>
                    </div>
                </y-panel>
            </div>
        </div>
    </y-panel>
</template>

<script>

    import { YFormField } from '@deps';
    import YCategoryList from '@/modules/market/components/CampaignEditor/CategoryPanel/CategoryList';
    import YProductList from '@/modules/market/components/CampaignEditor/CategoryPanel/ProductList';
    import YSearchInput from '@/modules/market/components/CampaignEditor/CategoryPanel/SearchInput';

    export default {
        name: 'CategoryPanel',

        components: {
            YFormField,
            YCategoryList,
            YProductList,
            YSearchInput,
        },

        props: {
            /**
             * Metadata of the Model
             */
            metadata: Object,

            /**
             * Value of the Model
             */
            value: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model           : this.value || {},
                products        : null,
                selectedCategory: null,
                query           : {
                    category      : null,
                    categorySearch: false,
                    productSearch : false,
                    categoryResult: null,
                    product       : null,
                },
            };
        },

        computed: {
            /**
             * Return the type options
             */
            typesOptions() {
                if (this.metadata.types.length === 1) {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.$set(this.model, 'type_id', this.metadata.types[0].value);
                }
                return this.metadata && this.metadata.types ? this.metadata.types : [];
            },

            /**
             * Return the categories
             */
            categories() {
                let category = this.metadata && this.metadata.categories ? this.metadata.categories : [];
                if (this.query.categorySearch) {
                    category = this.query.categoryResult;
                }
                return category || [];
            },

        },

        watch: {
            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

        },

        methods: {
            /**
             * on Category Search Typing
             */
            onCategorySearchTyping() {
                this.$wait.start('fetch-category');
            },

            /**
             * on Category Search
             *
             * @param {string} value - debounced search value
             */
            onCategorySearch(value) {
                this.query.category = value;
                this.fetchCategory();
            },

            /**
             * on Product Search Typing
             */
            onProductSearchTyping() {
                this.$wait.start('fetch-product');
            },

            /**
             * on Product Search
             *
             * @param {string} value - debounced search value
             */
            onProductSearch(value) {
                this.query.product = value;
                this.fetchProduct();
            },

            /**
             * Fetch Category List
             *
             * @param {*} typeId - type id
             */
            fetchCategory(typeId) {
                this.$wait.start('fetch-category');
                this.query.categorySearch = true;
                const params = {
                    type_id: typeId || this.model.type_id,
                    search : this.query.category,
                };
                return this.$services.Market.campaignCategories(params).then((response) => {
                    this.query.categoryResult = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('fetch-category');
                });
            },

            /**
             * Fetch Product List
             */
            fetchProduct() {
                this.$wait.start('fetch-product');
                let params = {
                    type_id: this.model.type_id,
                };
                if (this.selectedCategory) {
                    this.query.productSearch = false;
                    params = {
                        ...params,
                        category_id: this.selectedCategory.id,
                    };
                } else {
                    this.query.productSearch = true;
                    params = {
                        ...params,
                        search: this.query.product,
                    };
                }
                return this.$services.Market.campaignProducts(params).then((response) => {
                    this.products = response.data.results;
                }).catch((err) => {
                    this.handleError(err);
                }).finally(() => {
                    this.$wait.end('fetch-product');
                });
            },

            /**
             * handle click on category item
             *
             * @param {string} id - Selected item id
             * @param {string} title - Selected item title
             */
            handleClick(id, title) {
                this.selectedCategory = {
                    id,
                    title,
                };
                this.query.product = '';
                this.fetchProduct();
            },

            /**
             * reset products result
             */
            resetProducts() {
                this.products = null;
                this.selectedCategory = null;
            },

            /**
             * reset results on type change
             *
             * @param e
             */
            onTypeChange(e) {
                this.products = null;
                this.query = {
                    category      : null,
                    categorySearch: false,
                    categoryResult: null,
                    product       : null,
                };
                this.selectedCategory = null;
                // Reset selected categories / product models
                this.model._categories = [];
                this.model.category_ids = [];
                this.model._posts = [];
                this.model.post_ids = [];
                this.$refs.categorySearchInput.reset();
                this.$refs.productSearchInput.reset();
                this.fetchCategory(e.value);
            },
        },
    };

</script>
