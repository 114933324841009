<template>
    <div class="field icon">
        <y-form-input
            v-model="searchInput"
            type="search"
            class="sm"
            :placeholder="$t('fields.search')"
            :disabled="disabled"
            @input="debounceInput"
        >
            <i class="icon md-magnify" />
        </y-form-input>
    </div>
</template>

<script>
    import { YFormInput } from '@deps/form/elements/Input';
    import { debounce } from '@nodes/helpers/api';

    export default {
        name: 'SearchInput',

        components: { YFormInput },

        props: {
            /**
             * input disabled
             */
            disabled: Boolean,

            /**
             * debounce time
             */
            debounceTime: {
                type   : Number,
                default: 500,
            },

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                searchInput: '',
            };
        },

        watch: {
            /**
             * watch search input
             * emmit typing event
             */
            searchInput() {
                this.$emit('typing');
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            /**
             * debouncing searchInput input
             */
            const self = this;
            this.debounceInput = debounce(() => {
                if (!self.disabled) {
                    self.$emit('search', this.searchInput);
                }
            }, self.debounceTime, false);
        },

        methods: {
            /**
             * reset search input
             */
            reset() {
                this.searchInput = '';
            },
        },
    };
</script>
