<template>
    <!-- eslint-disable vue/no-mutating-props -->
    <div>
        <y-panel :title="$t('ware.settings.post')">
            <div class="setting-product-info d-f justify-content-start align-items-center">
                <img
                    v-if="productImage && productImage.length && productImage.thumbnail"
                    :src="productImage.thumbnail"
                    :alt="productTitle"
                >
                <span
                    v-else
                    class="no-image"
                >
                    <i class="icon md-store" />
                </span>

                <div>
                    <h5 class="title">
                        {{ productTitle }}
                    </h5>
                    <span
                        class="label"
                        :class="metadata.product_is_available ? 'green' : 'red'"
                    >
                        {{ metadata.product_is_available ? $t('market.product.salable') : $t('market.product.unsalable') }}
                    </span>
                </div>
            </div>
        </y-panel>

        <y-panel :title="$t('ware.settings.code')">
            <y-form-field
                v-model="model.code"
                type="number"
                name="market_code"
                :placeholder="!isEdit && autoWareCode ? $t('ware.settings.auto_ware_code') : ''"
                :disabled="autoWareCode"
            />

            <y-alert
                v-if="isEdit && autoWareCode"
                color="blue"
                :message="`${$t('ware.settings.auto_ware_code')}.`"
            />

            <y-form-field
                v-model="model.is_available"
                type="checkbox"
                name="ware_is_available"
            />
        </y-panel>

        <y-panel
            v-if="metadata.has_tax"
            :title="$t('ware.settings.tax')"
        >
            <y-form-field
                v-if="metadata.has_tax"
                v-model="model.tax_override"
                type="checkbox"
                name="activate_tax"
            />

            <y-row v-if="model.tax_override">
                <y-col>
                    <y-form-field
                        v-model="model.tax_value_added"
                        type="checkbox"
                        :label="$t('ware.tax.value_added', [digitTax(metadata.tax_value_added)])"
                    />
                </y-col>
                <y-col>
                    <y-form-field
                        v-model="model.tax_withholding"
                        type="checkbox"
                        :label="$t('ware.tax.withholding', [digitTax(metadata.tax_withholding)])"
                    />
                </y-col>
                <y-col>
                    <y-form-field
                        v-model="model.tax_municipality"
                        type="checkbox"
                        :label="$t('ware.tax.municipality', [digitTax(metadata.tax_municipality)])"
                    />
                </y-col>
            </y-row>
        </y-panel>
    </div>
</template>

<script>

    import { digits } from '@nodes/helpers/number';
    import { YFormField, YAlert } from '@deps';

    export default {

        name: 'WareSetting',

        components: {
            YFormField,
            YAlert,
        },

        props: {
            model       : [Object, Array],
            metadata    : [Object, Array],
            productTitle: String,
            productImage: [Object, Array],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                autoWareCode: true,
                taxOptions  : {
                    separate: this.$t('ware.tax.separate'),
                    on      : this.$t('ware.tax.on'),
                },
            };
        },

        computed: {
            /**
             * Check if is edit mode
             */
            isEdit() {
                return !!this.$route.params.wareid;
            },
        },

        watch: {
            /**
             * Watch model and emit it
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * Set Default model
         */
        created() {
            // Setting
            if (!this.model.code) {
                this.$set(this.model, 'code', '');
            }
            if (!this.model.is_available && !this.isEdit) {
                this.$set(this.model, 'is_available', 1);
            }

            if (this.model.tax_value_added && this.model.tax_value_added > 0) {
                this.$set(this.model, 'tax_value_added', 1);
            } else {
                this.$set(this.model, 'tax_value_added', 0);
            }

            if (this.model.tax_withholding && this.model.tax_withholding > 0) {
                this.$set(this.model, 'tax_withholding', 1);
            } else {
                this.$set(this.model, 'tax_withholding', 0);
            }

            if (this.model.tax_municipality && this.model.tax_municipality > 0) {
                this.$set(this.model, 'tax_municipality', 1);
            } else {
                this.$set(this.model, 'tax_municipality', 0);
            }

            this.checkAutoWare();
        },

        methods: {
            /**
             * Check if auto ware code is enabled
             */
            checkAutoWare() {
                this.$set(this, 'autoWareCode', this.metadata.ware_code_auto);
            },

            /**
             * Return digits to the trans
             *
             * @param value
             */
            digitTax(value) {
                return digits(value, this.$i18n.locale);
            },
        },

    };

</script>
