<template>
    <y-panel
        class="campaigns-editor-group"
        :title="$t('campaigns.editor.date.panel')"
    >
        <div class="row">
            <div class="col-6">
                <y-form-date-picker
                    v-model="model.starts_at"
                    type="date"
                    name="starts_at"
                    :label="$t('campaigns.editor.date.starts_at')"
                    validation="required"
                />
            </div>
            <div class="col-6">
                <y-form-date-picker
                    v-model="model.ends_at"
                    type="date"
                    name="ends_at"
                    :label="$t('campaigns.editor.date.ends_at')"
                    validation="required"
                />
            </div>
        </div>
    </y-panel>
</template>

<script>
    import YFormDatePicker from '@deps/form/elements/DatePicker';

    export default {
        name: 'DatePanel',

        components: {
            YFormDatePicker,
        },

        props: {
            /**
             * Campaign editor model
             */
            value: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},
            };
        },

        watch: {
            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },
    };
</script>
