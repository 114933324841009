<template>
    <div>
        <y-row>
            <y-col>
                <y-form-field
                    v-model="model.mass"
                    type="number"
                    format="separated"
                    name="weight"
                    :label="$t('fields.weight', [$t('market.units.gr')])"
                />
            </y-col>
            <y-col v-if="element.approximate_sizes && element.approximate_sizes.length">
                <y-form-field
                    v-model="model.size"
                    type="select"
                    name="approximate_size"
                    :options="element.approximate_sizes"
                    validation="required"
                />
            </y-col>
        </y-row>
        <y-row>
            <y-col>
                <y-form-field
                    v-model="model.length"
                    type="number"
                    format="separated"
                    name="length"
                    :label="$t('fields.length', [$t('market.units.cm')])"
                />
            </y-col>
            <y-col>
                <y-form-field
                    v-model="model.width"
                    type="number"
                    format="separated"
                    name="width"
                    :label="$t('fields.width', [$t('market.units.cm')])"
                />
            </y-col>
            <y-col>
                <y-form-field
                    v-model="model.height"
                    type="number"
                    format="separated"
                    name="height"
                    :label="$t('fields.height', [$t('market.units.cm')])"
                />
            </y-col>
        </y-row>
        <y-form-field
            v-model="model.breakable"
            type="checkbox"
            name="breakable"
        />
    </div>
</template>

<script>

    import WareTabMixin from '@/modules/market/mixins/WareTabMixin';

    export default {

        name: 'WareTabSizes',

        mixins: [WareTabMixin],

    };

</script>
