<template>
    <div>
        <y-loading
            height="300px"
            :active="$wait.is('fetch-product')"
        >
            <div

                v-if="products && products.length"
                class="product-items"
            >
                <y-form-checkbox
                    v-for="product in products"
                    :key="product.hashid"
                    :label="product.title"
                    type="checkbox"
                    class="product-items__item"
                    :value="isProductSelected(product.hashid)"
                    @change="onSelectToggle(product.hashid, product.title)"
                />
            </div>

            <y-empty-state
                v-else
                class="mb0"
                height="300"
                :message="$t('campaigns.editor.category.empty_product')"
            />
        </y-loading>
        <div class="added-products-wrapper">
            <y-added-division
                :list="model._posts"
                class="max-content"
                @remove="onRemove"
            />
        </div>
    </div>
</template>

<script>
    import { YFormCheckbox } from '@deps';
    import YEmptyState from '@deps/EmptyState';
    import YAddedDivision from '@/modules/market/components/CampaignEditor/CategoryPanel/AddedDivisions';

    export default {
        name: 'ProductList',

        components: {
            YFormCheckbox,
            YEmptyState,
            YAddedDivision,
        },

        props: {
            products: Array,
            value   : Object,
        },


        /**
         * @inheritdoc
         */
        data() {
            return {
                model: this.value || {},
            };
        },

        watch: {
            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            if (typeof this.model.post_ids === 'undefined' || this.model.post_ids === null) {
                this.model.post_ids = [];
            }
        },

        methods: {
            /**
             * Define is the product selected?
             *
             * @param {string} hashid - product hashid
             * @returns {boolean} is selected
             */
            isProductSelected(hashid) {
                return this.model.post_ids.indexOf(hashid) !== -1;
            },
            /**
             * handle toggle item
             *
             * @param {string} hashid - item hashid
             * @param {string} title - item title
             */
            onSelectToggle(hashid, title) {
                const oldSelectedProducts = this.model.post_ids ?? [];
                const oldSelectedProductsList = this.model._posts ?? [];
                let newSelectedProducts = oldSelectedProducts;
                let newSelectedProductsList = oldSelectedProductsList;
                if (this.isProductSelected(hashid)) {
                    newSelectedProducts = oldSelectedProducts.filter((oldItem) => oldItem !== hashid);
                    newSelectedProductsList = oldSelectedProductsList.filter((oldItem) => oldItem.id !== hashid);
                } else {
                    newSelectedProducts = [...oldSelectedProducts, hashid];
                    newSelectedProductsList = [...oldSelectedProductsList, { id: hashid, title }];
                }

                this.$set(this, 'model', { ...this.model, post_ids: newSelectedProducts, _posts: newSelectedProductsList });
            },
            /**
             * handle on remove item from selected items
             *
             * @param {string} id - item id
             */
            onRemove(id) {
                const newSelectedProducts = this.model.post_ids.filter((item) => item !== id);
                const newSelectedProductsList = this.model._posts.filter((oldItem) => oldItem.id !== id);
                this.$set(this, 'model', { ...this.model, post_ids: newSelectedProducts, _posts: newSelectedProductsList });
            },
        },
    };
</script>
