<template>
    <y-loading
        class="panel-grid-layout"
        :active="$wait.is('loading-page')"
        height="calc( 100vh - 121px )"
    >
        <template v-if="form">
            <y-page-head
                :title="pageHeadTitle"
                icon="md-basket"
            >
                <!-- Actions -->
                <template slot="actions">
                    <y-button
                        id="refresh-button"
                        type="button"
                        icon="md-refresh"
                        aria-label="Refresh"
                        @click.prevent="reFetch"
                    />
                    <y-button
                        v-if="isEdit && can_manage_wares"
                        tag="router-link"
                        :to="waresManageLink"
                    >
                        {{ $t('market.wares.manage') }}
                    </y-button>
                </template>

                <!-- Tabs -->
                <template slot="tabs">
                    <a
                        v-for="tab in form.tabs"
                        :key="`tab-title-${tab.id}`"
                        href="#"
                        :class="{active: activeTab === tab.id}"
                        @click.prevent="changeTab(tab.id)"
                    >
                        <i
                            v-if="tab.icon"
                            class="icon"
                            :class="[tab.icon]"
                        />
                        {{ tab.label }}
                    </a>
                </template>
            </y-page-head>

            <main>
                <form
                    id="content_post"
                    @submit.prevent="submit(save)"
                >
                    <y-product-form
                        v-model="model"
                        :form="form"
                        :active-tab="activeTab"
                        @action="handleSubmit"
                    />
                </form>
            </main>
        </template>
    </y-loading>
</template>

<script>

    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    import YProductForm from '@/modules/market/components/ProductForm';

    export default {
        name: 'MarketProductEdit',

        components: {
            YProductForm,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t(`breadcrumbs.content.post.${this.isEdit ? 'edit' : 'create'}`)),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t(`content.post.${this.type}`),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model           : {},
                form            : null,
                can_manage_wares: false,
                submitType      : null,

                activeTab: null,
            };
        },

        computed: {

            /**
             * Check if is edit mode
             */
            isEdit() {
                return !!this.$route.params.id;
            },

            /**
             * Is clone
             */
            isClone() {
                return !!this.$route.query.clone;
            },

            /**
             * Check if is edit mode
             */
            type() {
                return this.isEdit ? 'edit' : 'create';
            },

            /**
             * Return type if content
             */
            contentType() {
                return this.$route.params.type;
            },

            /**
             * Return lang of post
             */
            lang() {
                return this.$route.query.lang;
            },

            /**
             * Return sisterhood of post
             */
            sisterhood() {
                return this.$route.query.sisterhood;
            },

            /**
             * Page title
             */
            pageHeadTitle() {
                if (this.isEdit) {
                    return this.$t('market.product.edit', [this.model.title]);
                }

                return !this.model.title
                    ? this.$t('market.product.create')
                    : this.$t('market.product.createWithTitle', [this.model.title]);
            },

            /**
             * Manage wares link
             */
            waresManageLink() {
                return {
                    name  : 'market-wares-list',
                    params: {
                        type: this.$route.params.type,
                        id  : this.$route.params.id,
                    },
                };
            },

        },

        methods: {

            /**
             * Fetch content type form and value
             */
            fetch() {
                const params = {
                    id        : this.$route.params.id || this.$route.query.clone || null,
                    clone     : this.isClone ? 1 : null,
                    type      : this.$route.params.type,
                    lang      : this.lang,
                    sisterhood: this.sisterhood,
                };
                return this.$services.Market.postFetch(params).then((response) => {
                    const model = response.data.results;

                    if (this.isClone) {
                        delete model.id;
                    }

                    if (this.lang || this.sisterhood) {
                        model.lang = this.lang;
                        model.sisterhood = this.sisterhood;
                    }

                    this.$set(this, 'model', model);
                    this.$set(this, 'form', response.data.metadata.form);
                    this.$set(this, 'activeTab', this.get(this.form, 'tabs.0.id'));
                    this.$set(this, 'can_manage_wares', response.data.metadata.can_manage_wares);
                    this.$set(this.form, 'published_at', response.data.results.published_at);
                    this.$set(this.form, 'published_by', response.data.results.published_by);
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Handle submit content post form
             *
             * @param payload
             */
            handleSubmit(payload) {
                this.submitType = payload.type;

                if (payload.published_at) {
                    this.model.published_at = payload.published_at;
                }
                if (payload.moderation_note) {
                    this.model.moderation_note = payload.moderation_note;
                }

                this.save();
            },

            /**
             * Save content type form
             *
             * @returns {*}
             */
            save() {
                const params = {
                    ...this.model,
                    _submit: this.submitType,
                    type   : this.$route.params.type,
                };
                this.$wait.start('content-submitting');
                this.$wait.start(`content-submitting-${this.submitType}`);
                return this.$services.Market.postSave(params).then((response) => {
                    this.$toast.success(this.$t(`content.success.${this.submitType}`));
                    this.handleAfterSave(response);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('content-submitting');
                    this.$wait.end(`content-submitting-${this.submitType}`);
                });
            },

            /**
             * Refresh buttons
             */
            refreshButtons() {
                this.$wait.start('content-refresh-buttons');
                const params = {
                    id: this.$route.params.id,
                };
                this.$services.Content.postFetchButtons(params).then((response) => {
                    this.$set(this, 'form', { ...this.form, ...response.data.metadata.form });
                    this.$set(this.form, 'published_at', response.data.results.published_at);
                    this.$set(this.form, 'published_by', response.data.results.published_by);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.$wait.end('content-refresh-buttons'));
            },

            /**
             * Handle after save action
             *
             * @param response
             */
            handleAfterSave(response) {
                if (this.submitType === 'delete') {
                    this.redirectToList();
                } else if (!this.isEdit) {
                    this.redirectToEdit(response.data.metadata.hashid);
                } else {
                    this.refreshButtons();
                }
            },

            /**
             * Redirect to the list of posts
             */
            redirectToList() {
                this.$router.push({
                    name  : 'market-product-list',
                    params: {
                        type: this.contentType,
                    },
                });
            },

            /**
             * Redirect to the list of posts
             *
             * @param id
             */
            redirectToEdit(id) {
                this.$router.push({
                    name  : 'market-product-edit',
                    params: {
                        type: this.contentType,
                        id,
                    },
                });
            },

            /**
             * Change tab
             *
             * @param tab
             */
            changeTab(tab) {
                this.activeTab = tab;
            },
        },

    };
</script>
