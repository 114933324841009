<template>
    <y-cloner-wrapper
        v-slot="clonerRow"
        :rows="model"
    >
        <div class="row sm">
            <div class="col-8">
                <y-form-field
                    v-if="waresOptions"
                    v-model="model[clonerRow.index].id"
                    type="select"
                    :options="waresOptions"
                    label-field="title"
                    value-field="id"
                    no-label
                    :placeholder="$t('market.wares.cloner.ware')"
                />
            </div>
            <div class="col-sm-2">
                <y-form-field
                    v-model="model[clonerRow.index].fee"
                    type="number"
                    :placeholder="$t('market.wares.cloner.fee')"
                    no-label
                />
            </div>

            <div class="col-sm-2">
                <y-form-field
                    v-model="model[clonerRow.index].alter"
                    type="number"
                    :placeholder="$t('market.wares.cloner.count')"
                    no-label
                />
            </div>
        </div>
    </y-cloner-wrapper>
</template>

<script>

    import ClonerMixin from '@/mixins/Cloner';

    /**
     * Cloner Component
     */
    export default {

        name: 'DependentCloner',

        mixins: [ClonerMixin],

        /**
         * @inheritDoc
         */
        data() {
            return {
                waresOptions: null,
            };
        },

        /**
         * @inheritDoc
         */
        created() {
            this.fetchWares();
        },

        methods: {
            /**
             * Fetch wares to use in select
             */
            fetchWares() {
                const params = {
                    including: ['title'],
                };
                this.$services.Market.dependableWares(params).then((response) => {
                    this.waresOptions = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                });
            },
        },

    };

</script>
