<template>
    <div
        v-if="list && list.length"
        class="tag-box"
    >
        <span
            v-for="item in list"
            :key="item.id"
            class="label green"
        >
            {{ item.title }}
            <i
                class="icon md-close"
                @click="$emit('remove', item.id)"
            />
        </span>
    </div>
</template>

<script>
    export default {
        name: 'CampaignEditorAddedDivisions',

        props: {
            /**
             * List of selected categories
             */
            list: Array,
        },
    };
</script>
