<template>
    <div>
        <y-page-head
            :title="title"
            icon="md-brightness-percent"
            :class="[{disabled: $wait.is('loading-page')}]"
        >
            <template slot="actions">
                <y-button
                    v-if="isEdit"
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />

                <!-- Save -->
                <y-button
                    color="blue"
                    form="main-form"
                    wide
                    loading-on="submitting-form"
                >
                    {{ $t('button.save') }}
                </y-button>
            </template>
        </y-page-head>

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <main class="campaigns-editor">
                <form
                    id="main-form"
                    @submit.prevent="submit(save)"
                >
                    <div class="row">
                        <div class="col-sm-8">
                            <y-base-panel
                                v-model="model"
                                :metadata="metadata"
                            />

                            <y-category-panel
                                v-model="model"
                                :metadata="metadata"
                            />
                        </div>
                        <div class="col-sm-4">
                            <y-slug-panel v-model="model" />

                            <y-date-panel v-model="model" />

                            <y-amount-panel
                                v-model="model"
                                :metadata="metadata"
                            />
                        </div>
                    </div>
                </form>
            </main>
        </y-loading>
    </div>
</template>

<script>

    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';
    import YBasePanel from '@/modules/market/components/CampaignEditor/BasePanel';
    import YAmountPanel from '@/modules/market/components/CampaignEditor/AmountPanel';
    import YCategoryPanel from '@/modules/market/components/CampaignEditor/CategoryPanel';
    import YSlugPanel from '@/modules/market/components/CampaignEditor/SlugPanel';
    import YDatePanel from '@/modules/market/components/CampaignEditor/DatePanel';

    export default {
        name: 'CampaignEdit',

        components: {
            YBasePanel,
            YAmountPanel,
            YCategoryPanel,
            YSlugPanel,
            YDatePanel,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.title,
            };
        },

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc({
                    name  : 'panel-grid',
                    params: {
                        class  : 'campaigns',
                        module : 'Market',
                        part   : 'list',
                        version: '1',
                    },
                }, this.$t('breadcrumbs.campaigns.list')),
                this.$bc(this.$t(`breadcrumbs.campaigns.${this.$route.params.id ? 'edit' : 'create'}`)),
            ];
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model             : {},
                metadata          : null,
                types             : [],
                searchedCategories: [],
            };
        },

        computed: {
            /**
             * Title of the page
             */
            title() {
                return this.isEdit ? this.$t('campaigns.title.edit') : this.$t('campaigns.title.create');
            },

            /**
             * Check if it is in edit mode
             */
            isEdit() {
                return !!this.$route.params.id;
            },
        },

        methods: {
            /**
             * Fetch Campaign Editor form data
             */
            fetch() {
                this.$wait.start('loading-page');
                const params = {
                    id: this.$route.params.id,
                };
                return this.$services.Market.campaignFetch(params).then((response) => {
                    this.metadata = response.data.metadata;
                    this.model = response.data.results;

                    if (!this.model.titles) {
                        this.model.titles = {};
                    }
                    if (this.model.type_id === null && this.metadata.types && this.metadata.types.length > 0) {
                        this.model.type_id = this.metadata.types[0].value;
                    }
                }).catch((err) => {
                    this.handleError(err);
                }).finally(() => {
                    this.$wait.end('loading-page');
                });
            },

            /**
             * Save Campaign
             */
            save() {
                this.$wait.start('submitting-form');
                let params = this.model;

                // clean Applicable Amounts array to remove unused items
                if (params.applicable_amounts) {
                    const cleanApplicableAmounts = params.applicable_amounts.filter((item) => item.currency && item.amount);
                    params = {
                        ...params,
                        applicable_amounts: cleanApplicableAmounts,
                    };
                }

                return this.$services.Market.campaignSave(params).then(() => {
                    this.$toast.success(this.$t('campaigns.editor.messages.success'));
                    this.redirectToShippingGrid();
                }).catch((err) => {
                    this.handleError(err);
                }).finally(() => {
                    this.$wait.end('submitting-form');
                });
            },

            /**
             * Postprocessing for save method
             */
            redirectToShippingGrid() {
                this.$router.push({
                    name  : 'panel-grid',
                    params: {
                        class  : 'campaigns',
                        module : 'Market',
                        part   : 'list',
                        version: '1',
                    },
                });
            },
        },
    };

</script>
