<template>
    <y-modal
        ref="Modal"
        class="inventory-modal"
        :title="title"
        forced
    >
        <y-loading
            :active="$wait.is('loading-post')"
            height="200px"
        >
            <form
                id="inventory-form"
                class="p20"
                @submit.prevent="submit(save)"
            >
                <y-form
                    v-model="model"
                    :params="[form]"
                />
            </form>
        </y-loading>

        <template
            v-if="!$wait.is('loading-post')"
            slot="footer"
        >
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="blue"
                    form="inventory-form"
                    loading-on="submitting-form"
                >
                    {{ $t('button.save') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import FormMixin from '@/mixins/Form';
    import { YModal, YForm } from '@deps';

    export default {
        name: 'InventoryChangeModal',

        components: {
            YModal,
            YForm,
        },

        mixins: [FormMixin],

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {},
                form : {
                    component_name: 'inventory-change',
                    id            : 'inventory-change',
                },

                requests: [],
            };
        },

        computed: {
            /**
             * Return modal title
             */
            title() {
                return this.id
                    ? this.$t('market.wares.edit_modal')
                    : this.$t('market.wares.create_modal');
            },
        },

        methods: {
            /**
             * Save form
             */
            save() {
                const params = {
                    ...this.model,
                };
                return this.$services.Market.inventorySave(params).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('market.wares.type') }));
                    this.$emit('done', this.model.label);
                    this.close();
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Open modal
             *
             * @param wareCombo
             */
            open(wareCombo) {
                this.$set(this.form, 'wareCombo', wareCombo);
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.reset();
            },

            /**
             * Reset modal data
             */
            reset() {
                this.model = {};
            },

        },
    };
</script>
