<template>
    <div>
        <template v-for="child in children">
            <div :key="child.id">
                <div class="category-list-row">
                    <y-form-checkbox
                        :disabled="disabled"
                        class="cat-items__item"
                        :name="`category-${child.id}`"
                        :value="isCategorySelected(child.id)"
                        type="checkbox"
                        no-label
                        @change="handleToggleSelect(child.id, child.title)"
                    />
                    <label
                        :class="{'not-allowed': isCategorySelected(child.id), 'disabled': disabled }"
                        @click="handleLabelClick(child.id, child.title)"
                    >{{ child.title }}</label>
                </div>
                <template v-if="child.children && child.children.length">
                    <CategoryListChild
                        :selected-categories="selectedCategories"
                        :disabled="isCategorySelected(child.id) || disabled"
                        class="cat-subitem"
                        :children="child.children"
                        @toggle="handleToggleSelect"
                        @click="handleLabelClick"
                    />
                </template>
            </div>
        </template>
    </div>
</template>

<script>
    import { YFormCheckbox } from '@deps';

    export default {
        name: 'CategoryListChild',

        components: {
            YFormCheckbox,
        },

        props: {
            selectedCategories: Array,
            children          : [Array, Object],
            disabled          : Boolean,
        },
        
        methods: {
            /**
             * Define is the category selected?
             *
             * @param {string} id - category id
             * @returns {boolean} is selected
             */
            isCategorySelected(id) {
                return this.selectedCategories.indexOf(id) !== -1;
            },
            /**
             * Handle toggle on item select
             *
             * @param {string} id - Selected item id
             * @param {string} title - Selected item title
             */
            handleToggleSelect(id, title) {
                this.$emit('toggle', id, title);
            },
            /**
             * Handle click on label item
             * if category is not selected or not disabled
             *
             * @param {string} id - Selected item id
             * @param {string} title - Selected item title
             */
            handleLabelClick(id, title) {
                if (!this.isCategorySelected(id) || this.disabled) {
                    this.$emit('click', id, title);
                }
            },
        },
    };
</script>
