<template>
    <div>
        <y-loading
            class="categories-tree-wrapper"
            height="350px"
            :active="$wait.is('fetch-category')"
        >
            <template v-if="categoriesTree && categoriesTree.length">
                <div
                    v-for="(cat, index) in categoriesTree"
                    :key="index"
                >
                    <template v-if="cat.roots.length">
                        <span class="cat-title">{{ cat.title }}</span>
                        <div class="cat-items">
                            <y-category-list-child
                                :selected-categories="model.category_ids"
                                :children="cat.roots"
                                @toggle="onSelectToggle"
                                @click="handleClick"
                            />
                        </div>
                    </template>
                </div>
            </template>

            <y-empty-state
                v-else
                class="mb0"
                :message="$t('campaigns.editor.category.empty_cat')"
            />
        </y-loading>
        <div class="added-categories-wrapper">
            <y-added-division
                :list="model._categories"
                @remove="onRemove"
            />
        </div>
    </div>
</template>

<script>
    import YEmptyState from '@deps/EmptyState';
    import YCategoryListChild from '@/modules/market/components/CampaignEditor/CategoryPanel/CategoryListChild';
    import YAddedDivision from '@/modules/market/components/CampaignEditor/CategoryPanel/AddedDivisions';

    export default {
        name: 'CategoryList',

        components: {
            YEmptyState,
            YCategoryListChild,
            YAddedDivision,
        },

        props: {
            categories: Array,
            value     : Object,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                model         : this.value || {},
                categoriesTree: null,
            };
        },
        
        watch: {
            categories: {
                /**
                 * Handle watch categories
                 *
                 * @param newVal
                 */
                handler(newVal) {
                    this.categoriesTree = this.getCategoriesTreeList(newVal);
                },
                deep: true,
            },
            
            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.categoriesTree = this.getCategoriesTreeList(this.categories);
        },

        methods: {
            /**
             * Create Tree array list for categories
             *
             * @param categoryFolders
             * @returns {Array} tree array of categories
             */
            getCategoriesTreeList(categoryFolders) {
                return categoryFolders.map((categoryFolder) => {
                    const { labels: list } = categoryFolder;
                    const map = {};
                    let node;
                    const roots = [];
                    let i;
  
                    for (i = 0; i < list.length; i += 1) {
                        map[list[i].id] = i; // initialize the map
                        list[i].children = []; // initialize the children
                    }
  
                    for (i = 0; i < list.length; i += 1) {
                        node = { ...list[i] };

                        // if you have dangling branches check that map[node.parent_id] exists
                        if (node.parent_id === null || typeof map[node.parent_id] === 'undefined' ) {
                            roots.push(node);
                        } else {
                            list[map[node.parent_id]].children.push(node);
                        }
                    }
                    return { slug: categoryFolder.slug, title: categoryFolder.title, roots };
                });
            },

            /**
             * handle toggle item
             *
             * @param {string} id - item id
             * @param {string} title - item title
             */
            onSelectToggle(id, title) {
                const oldSelectedCategories = this.model.category_ids;
                const oldSelectedCategoriesList = this.model._categories ?? [];
                let newSelectedCategories = oldSelectedCategories;
                let newSelectedCategoriesList = oldSelectedCategoriesList;
                if (this.model.category_ids.indexOf(id) !== -1) {
                    newSelectedCategories = oldSelectedCategories.filter((oldItem) => oldItem !== id);
                    newSelectedCategoriesList = oldSelectedCategoriesList.filter((oldItem) => oldItem.id !== id);
                } else {
                    newSelectedCategories = [...oldSelectedCategories, id];
                    newSelectedCategoriesList = [...oldSelectedCategoriesList, { id, title }];
                }

                this.$set(this, 'model', { ...this.model, category_ids: newSelectedCategories, _categories: newSelectedCategoriesList });
            },
            /**
             * handle on remove item from selected items
             *
             * @param {string} id - item id 
             */
            onRemove(id) {
                const newSelectedCategories = this.model.category_ids.filter((item) => item !== id);
                const newSelectedCategoriesList = this.model._categories.filter((oldItem) => oldItem.id !== id);
                this.$set(this, 'model', { ...this.model, category_ids: newSelectedCategories, _categories: newSelectedCategoriesList });
            },

            /**
             * handle click on label item
             *
             * @param {string} id - Selected item id
             * @param {string} title - Selected item title
             */
            handleClick(id, title) {
                this.$emit('click', id, title);
            },
        },
    };
</script>
