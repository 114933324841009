<template>
    <div v-if="model.prices">
        <div
            v-for="currency in currencies"
            :key="`${element.id}-base-${currency.value}`"
            class="localed-inputs"
        >
            <div class="locale-title">
                {{ currency.label }}
            </div>

            <y-row>
                <y-col>
                    <y-form-field
                        v-model="model.prices[currency.value].original_price"
                        type="number"
                        name="original_price"
                        :label="priceLabel"
                        format="separated"
                        validation="required"
                    />
                    <y-row>
                        <y-col>
                            <y-form-field
                                v-model="model.prices[currency.value].discount"
                                type="number"
                                class="mb0"
                                name="discount"
                                :label="$t('fields.discount')"
                                format="separated"
                            />
                        </y-col>
                        <y-col>
                            <y-form-date-picker
                                v-model="model.prices[currency.value].expires_at"
                                type="datetime"
                                class="mb0"
                                name="expires_at"
                            />
                        </y-col>
                    </y-row>
                </y-col>
                <y-col>
                    <table class="table bordered">
                        <tbody>
                            <tr>
                                <th class="color-gray whs-nw">
                                    {{ $t('ware.payment.price') }}
                                </th>
                                <td class="readonly">
                                    <y-form-amount
                                        class="mb0"
                                        :element="{ value: model.prices[currency.value].original_price }"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th class="color-gray whs-nw">
                                    {{ $t(`ware.payment.price_with_discount`) }}
                                </th>
                                <td class="readonly">
                                    <y-form-amount
                                        v-if="model.prices[currency.value].original_price !== '0'"
                                        class="mb0"
                                        :element="{ value: (model.prices[currency.value].original_price - model.prices[currency.value].discount) }"
                                    />
                                    <y-form-amount
                                        v-else
                                        class="mb0"
                                        :element="{ value: '0' }"
                                    />
                                </td>
                            </tr>
                            <tr v-if="element.has_tax">
                                <th class="color-gray whs-nw">
                                    {{ $t('ware.payment.tax') }}
                                </th>
                                <td class="readonly">
                                    <y-form-amount
                                        v-if="model.prices[currency.value].original_price !== '0'"
                                        class="mb0"
                                        :element="{ value: calculateTax(model.prices[currency.value]) }"
                                    />
                                    <y-form-amount
                                        v-else
                                        class="mb0"
                                        :element="{ value: '0' }"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th class="color-gray whs-nw">
                                    {{ $t('ware.payment.sale_price') }}
                                </th>
                                <td class="readonly">
                                    <y-form-amount
                                        v-if="model.prices[currency.value].original_price !== '0'"
                                        class="mb0"
                                        :element="{ value: (model.prices[currency.value].original_price - model.prices[currency.value].discount) + (element.has_tax ? calculateTax(model.prices[currency.value]) : 0) }"
                                    />
                                    <y-form-amount
                                        v-else
                                        class="mb0"
                                        :element="{ value: '0' }"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </y-col>
            </y-row>
        </div>
    </div>
</template>

<script>

    import WareTabMixin from '@/modules/market/mixins/WareTabMixin';
    import YFormAmount from '@deps/form/elements/Amount';

    export default {

        name: 'WareTabPayment',

        components: {
            YFormAmount,
        },

        mixins: [WareTabMixin],

        /**
         * @inheritDoc
         */
        data() {
            return {
                checked: [],
            };
        },

        computed: {
            /**
             * Price Label based on tax
             */
            priceLabel() {
                let title = this.$t('fields.price.without_tax');
                if (this.element.has_tax) {
                    title = this.$t('fields.price.with_tax');
                }
                if (this.element.tax_should_add_to_price) {
                    title = this.$t('fields.price.without_tax');
                }
                return title;
            },
            /**
             * Return currencies of product
             */
            currencies() {
                return this.element.currencies;
            },

            /**
             * Sum of the tax percent
             */
            taxSum() {
                return (this.element.tax_municipality ? this.element.tax_municipality : 0)
                    + (this.element.tax_value_added ? this.element.tax_value_added : 0)
                    + (this.element.tax_withholding ? this.element.tax_withholding : 0);
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            if (!this.model.prices || this.isEmpty(this.model.prices)) {
                this.createPrices();
            }
        },

        methods: {
            /**
             * Create Model for model.prices
             */
            createPrices() {
                Object.values(this.currencies).forEach((currency) => {
                    this.set(this.model, `prices[${currency.value}].original_price`, '');
                    this.set(this.model, `prices[${currency.value}].discount`, '');
                    this.set(this.model, `prices[${currency.value}].expires_at`, '');
                });
            },

            /**
             * Calculate the tax of the ware
             *
             * @param ware
             */
            calculateTax(ware) {
                return ((ware.original_price - ware.discount) * this.taxSum) / 100;
            },
        },

    };

</script>
