<template>
    <y-panel
        class="campaigns-editor-base"
        :title="$t('campaigns.editor.base.panel')"
    >
        <template v-if="locales && locales.length">
            <div
                v-for="locale in locales"
                :key="`${locale}-base-${locale}`"
                class="localed-inputs"
            >
                <div class="locale-title">
                    {{ $t(`general.locales.${locale}`) }}
                </div>
                <y-form-field
                    v-model="model.titles[locale]"
                    type="text"
                    name="title"
                    validation="required"
                />
            </div>
        </template>
    </y-panel>
</template>

<script>

    import { YFormField } from '@deps';

    export default {
        name: 'CampaignsBasePanel',

        components: {
            YFormField,
        },

        props: {
            /**
             * List of active locales
             */
            metadata: [Array, Object],

            /**
             * Campaign editor model
             */
            value: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},
            };
        },

        computed: {
            /**
             * Return the locales
             */
            locales() {
                return this.metadata ? this.metadata.locales : [];
            },
        },

        watch: {
            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },
    };

</script>
