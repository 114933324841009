<template>
    <y-loading
        :active="$wait.is(`ware-item-${keyHelper}`)"
        height="200px"
    >
        <y-panel
            :title="truncate(item.title)"
            :subtitle="subtitle"
            icon="md-menu"
            class="slide-item ware-item"
        >
            <template slot="functions">
                <y-form-field
                    v-if="!wareDeleted"
                    v-model="active"
                    type="checkbox"
                    :name="`active-${item.id}`"
                    size="sm"
                    class="mb0"
                    switch
                    circular
                    color="green"
                    no-label
                    @click.native.prevent="changeAvailability"
                />

                <y-button
                    v-if="!wareDeleted"
                    key="up"
                    :disabled="!(permissions.can_edit)"
                    tag="router-link"
                    :to="edit(item.id)"
                    size="xs"
                    class="color-green"
                >
                    {{ $t('button.edit') }}
                </y-button>

                <y-button
                    v-if="hasInventory && !wareDeleted"
                    :disabled="!active"
                    tag="router-link"
                    :to="inventoryLink(item.id)"
                    size="xs"
                    class="color-blue"
                >
                    {{ $t('button.inventory_grid') }}
                </y-button>

                <y-button
                    v-if="!wareDeleted"
                    tag="router-link"
                    :to="cloneRoute(item.id)"
                    size="xs"
                    class="color-blue"
                >
                    {{ $t('button.clone') }}
                </y-button>

                <y-button
                    size="xs"
                    :color="wareDeleted ? 'green' : 'red'"
                    @click="deleteWare"
                >
                    {{ wareDeleted ? $t('button.restore') : $t('button.delete') }}
                </y-button>
            </template>
        
            <div class="row">
                <div
                    v-if="hasPrice"
                    class="col-sm-3"
                >
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('market.wares.original_price') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: item.original_price }" />
                        </div>
                    </div>
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('market.wares.original_price_average') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: item.sale_price_average }" />
                        </div>
                    </div>
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('market.wares.purchase_price_average') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: item.purchase_price_average }" />
                        </div>
                    </div>
                </div>
                <div
                    v-if="hasPrice"
                    class="col-sm-3"
                >
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('market.wares.current_inventory') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: item.current_inventory }" />
                        </div>
                    </div>
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('market.wares.sale_count') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: item.sale_count }" />
                        </div>
                    </div>
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('market.wares.currency') }}
                        </div>
                        <div class="value">
                            {{ item.currency_title ? item.currency_title : `-` }}
                        </div>
                    </div>
                </div>
                <div
                    v-if="hasPrice"
                    class="col-sm-3"
                >
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('market.wares.sale_price') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: item.sale_price }" />
                        </div>
                    </div>
                    <div
                        v-if="hasPrice"
                        class="detail-item"
                    >
                        <div class="title">
                            {{ $t('market.wares.sale_price_with_tax') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: item.sale_price_with_tax }" />
                        </div>
                    </div>
                    <div class="detail-item">
                        <div class="title">
                            {{ $t('market.wares.original_price_with_tax') }}
                        </div>
                        <div class="value">
                            <y-form-amount :element="{ value: item.original_price_with_tax }" />
                        </div>
                    </div>
                </div>
                <div :class="hasPrice ? 'col-sm-3' : 'row w-100 m0'">
                    <div :class="['detail-item', {'col-sm-3' : !hasPrice}]">
                        <div class="title">
                            {{ $t('market.wares.supply_type') }}
                        </div>
                        <div class="value">
                            {{ supplyType }}
                        </div>
                    </div>
                    <div :class="['detail-item', {'col-sm-3' : !hasPrice}]">
                        <div class="title">
                            {{ $t('market.wares.unit') }}
                        </div>
                        <div class="value">
                            {{ item.unit_title ? item.unit_title : `-` }}
                        </div>
                    </div>
                    <div
                        v-if="hasPrice"
                        class="detail-item"
                    >
                        <div class="title">
                            {{ $t('market.wares.sale_price_expires_at') }}
                        </div>
                        <div
                            v-if="item.sale_price_expires_at"
                            class="value"
                        >
                            {{ item.sale_price_expires_at | date('DD MMMM YYYY', $i18n.locale) }}
                        </div>
                        <div
                            v-else
                            class="value"
                        >
                            {{ `-` }}
                        </div>
                    </div>
                </div>
            </div>
        </y-panel>
    </y-loading>
</template>

<script>
    import { truncate, generateId } from '@nodes/helpers/string';
    import { YFormField } from '@deps';
    import YFormAmount from '@deps/form/elements/Amount/Amount';

    export default {

        name: 'WareItem',

        components: {
            YFormField,
            YFormAmount,
        },

        props: {
            /**
             * Item
             */
            item: Object,

            /**
             * Permissions
             */
            permissions: Object,

            /**
             * Check if has inventory
             */
            hasInventory: Boolean,

            /**
             * Check if has price
             */
            hasPrice: Boolean,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                active   : this.item.is_available,
                keyHelper: generateId(),
            };
        },

        computed: {
            /**
             * Subtitle of item
             */
            subtitle() {
                return this.active ? '' : `(${this.$t('general.inactive')})`;
            },

            /**
             * Check item is in loading mode
             */
            isLoading() {
                return this.$wait.is(`ware-item-${this.keyHelper}`);
            },

            /**
             * Return Supply Type
             */
            supplyType() {
                let type = this.$t('market.inventory_type.related');
                if (this.item.supply_type === 'unique independent') {
                    type = this.$t('market.inventory_type.unique');
                }
                if (this.item.supply_type === 'independent') {
                    type = this.$t('market.inventory_type.standalone');
                }
                return type;
            },

            /**
             * Is ware deleted
             */
            wareDeleted() {
                return !!this.item.deleted_at;
            },
        },

        methods: {

            /**
             *
             */
            startLoading() {
                this.$wait.start(`ware-item-${this.keyHelper}`);
            },

            /**
             *
             */
            endLoading() {
                this.$wait.end(`ware-item-${this.keyHelper}`);
            },

            /**
             * Change status of item
             */
            deleteWare() {
                const type = this.item.deleted_at ? 'restore' : 'trash';
                const msgType = this.item.deleted_at ? 'restore' : 'trash';
                this.startLoading();
                this.$services[type]('market-ware', this.item.id).then(() => {
                    this.$toast.success(this.$t(`market.wares.message.${msgType}`));
                    this.$emit('delete');
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.endLoading());
            },

            /**
             * Change the availability of the ware
             */
            changeAvailability() {
                const updateActive = !this.active;
                const msgType = updateActive ? 'activate' : 'deactivate';
                this.startLoading();
                const params = {
                    id          : this.item.id,
                    availability: updateActive ? 1 : 0,
                };
                this.$services.Market.wareAvailability(params).then(() => {
                    this.active = updateActive;
                    this.$toast.success(this.$t(`messages.${msgType}.success`, { type: this.$t('market.wares.type') }));
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.endLoading());
            },

            /**
             * Edit Ware
             *
             * @param id
             */
            edit(id) {
                return {
                    name  : 'market-wares-edit',
                    params: {
                        wareid: id,
                    },
                };
            },

            /**
             * Ware Inventory Grid
             *
             * @param id
             */
            inventoryLink(id) {
                return {
                    name  : 'panel-grid',
                    params: {
                        class  : 'inventories',
                        module : 'Market',
                        part   : 'list',
                        version: '1',
                    },
                    query: {
                        order: 'desc',
                        page : '1',
                        sort : 'created_at',
                        ware : id,
                    },
                };
            },

            /**
             * Clone Route
             * 
             * @param {string} id - ID of the ware
             */
            cloneRoute(id) {
                return {
                    name  : 'market-wares-create',
                    params: {
                        wareid: id,
                    },
                    query: {
                        clone: id,
                    },
                };
            },

            truncate,

        },

    };
</script>
