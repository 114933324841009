/*-----------------------------------------------------------------
- Ware tab mixin
-----------------------------------------------------------------*/
import { convertToSelect } from '@nodes/helpers/object';

export default {
    
    name: 'WareTabMixin',
    
    components: {
        YFormField     : () => import('@deps/form/FormField'),
        YFormDatePicker: () => import('@deps/form/elements/DatePicker'),
    },
    
    props: {
        element: [Object, Array],
        value  : Object,
    },
    
    /**
     * @inheritDoc
     */
    data() {
        return {
            model: this.value || {},
        };
    },

    watch: {
        model: {
            /**
             * handle model descriptions.
             * 
             * @param {object} newValue 
             * @param {object} oldValue 
             */
            handler(newValue, oldValue) {
                if (newValue && !this.isEqual(newValue, oldValue)) {
                    if ('descriptions' in this.model && this.model.descriptions === null) {
                        this.set(this.model, 'descriptions', {});
                    }
                }
            },
            deep     : true,
            immediate: true,
        },
    },
    
    methods: {
        /**
         * Check if object is empty
         *
         * @param obj
         */
        isEmpty(obj) { // eslint-disable-line consistent-return
            if ((typeof obj === 'object') && (obj !== null)) {
                return (Object.entries(obj).length === 0 && obj.constructor === Object);
            }
        },
        
        convertToSelect,
        
    },
    
};
