<template>
    <div>
        <y-row>
            <y-col>
                <y-form-field
                    v-model="model.alter"
                    type="number"
                    format="separated"
                    name="inventory_count"
                />
            </y-col>
            <y-col>
                <y-form-field
                    v-model="model.unit_id"
                    type="select"
                    name="supply_unit"
                    :options="element.units"
                />
            </y-col>
            <y-col>
                <y-form-field
                    v-model="model.inventory_low_level_alarm"
                    type="number"
                    format="separated"
                    name="inventory_low_level_alarm"
                />
            </y-col>
        </y-row>

        <hr class="gray-lightest">

        <y-form-field
            v-model="model.supply_type"
            type="select"
            name="supply_type"
            :options="types"
            :validation="model.alter > 0 ? 'required' : ''"
        />

        <!-- Related -->
        <div
            v-if="model.supply_type === 2"
            class="cloner"
        >
            <y-dependent-cloner v-model="model.dependent_wares" />
        </div>

        <!-- Unique -->
        <div v-else-if="model.supply_type === 1">
            <div
                v-if="(model.alter || 0) < 1"
                class="alert red"
            >
                {{ $t('market.wares.unique_wares.alert') }}
            </div>
            <y-form-field
                v-else
                id="unique_ware"
                v-model="model.unique_wares"
                type="array"
                name="unique_wares"
                :label="$t('market.inventory_unique_ware')"
            />
        </div>

        <hr class="gray-lightest">

        <y-row>
            <y-col>
                <y-form-field
                    v-model="model.path_id"
                    type="select"
                    name="inventory_payment_path"
                    :options="element.paths"
                    :validation="(model.supply_type === 0 || model.alter > 0) ? 'required' : ''"
                    label-field="title"
                    value-field="id"
                    @select="pathSelect($event)"
                />
            </y-col>
            <y-col>
                <y-form-field
                    v-model="model.fee"
                    type="number"
                    name="inventory_price"
                    format="separated"
                    :placeholder="selectedPathCurrency"
                    :validation="(model.supply_type === 0 || model.alter > 0) ? 'required' : ''"
                />
            </y-col>
        </y-row>

        <hr class="gray-lightest">

        <y-row>
            <y-col>
                <y-form-field
                    v-model="model.inventory_low_level_stop"
                    type="checkbox"
                    name="inventory_low_level_stop"
                />
            </y-col>
        </y-row>
    </div>
</template>

<script>

    import WareTabMixin from '@/modules/market/mixins/WareTabMixin';
    import YDependentCloner from '../DependentCloner';

    export default {

        name: 'WareTabInventory',

        components: {
            YDependentCloner,
        },

        mixins: [WareTabMixin],

        /**
         * @inheritDoc
         */
        data() {
            return {
                walletSearch        : '',
                types               : this.element.supply_types,
                selectedPathCurrency: null,
            };
        },

        watch: {

            /**
             * Handle changing supply_type
             */
            'model.supply_type': {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    if (val !== 'unique' || val !== 1) {
                        this.$set(this.model, 'unique_wares', []);
                    }
                    if (val !== 'related' || val !== 2) {
                        this.$set(this.model, 'dependent_wares', []);
                    }
                    if (val === 'unique' || val === 1) {
                        if (!this.model.alter) {
                            this.$set(this.model, 'alter', 1);
                        }
                    }
                },
            },

            /**
             * Handle decreasing alter
             */
            'model.alter': {
                // eslint-disable-next-line require-jsdoc
                handler(newCount, oldCount) {
                    if (newCount < oldCount) {
                        this.$set(this.model, 'unique_wares', this.model.unique_wares.slice(0, newCount));
                    }
                },
            },
        },

        methods: {
            /**
             * Get Selected Path data
             *
             * @param {object} item - Object of the selected path
             */
            pathSelect(item) {
                this.$set(this, 'selectedPathCurrency', item.first_currency);
            },
        },
    };

</script>
