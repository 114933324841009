<template>
    <y-row
        v-if="model && form"
        class="market-editor"
    >
        <!-- Main area -->
        <y-col size="8">
            <div class="content-form-main">
                <template v-for="tab in tabs">
                    <div
                        v-show="activeTab === tab.id"
                        :key="`tab-content-${tab.id}`"
                        class="tab-content"
                    >
                        <y-form
                            v-if="tab.form && tab.form.length"
                            v-model="model"
                            :params="tab.form"
                        />
                    </div>
                </template>
            </div>
        </y-col>

        <!-- Side area -->
        <y-col size="4">
            <!-- Buttons -->
            <y-post-buttons
                v-if="buttons && buttons.length"
                :form="form"
                @action="action"
            />

            <y-form
                v-if="form.side && form.side.length"
                v-model="model"
                :params="form.side"
            />
        </y-col>
    </y-row>
</template>

<script>

    // Components
    import { YForm } from '@deps';
    import YPostButtons from '@/modules/content/components/PostButtons';

    export default {

        name: 'ProductForm',

        components: {
            YForm,
            YPostButtons,
        },

        props: {
            /**
             * Form object
             */
            form: Object,

            /**
             * Content type details
             */
            type: Object,

            /**
             * Value of form
             */
            value: Object,

            /**
             * Active tab
             */
            activeTab: String,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},
            };
        },

        computed: {

            /**
             * Return buttons of form
             *
             * @returns {*}
             */
            buttons() {
                return this.get(this.form, 'buttons');
            },

            /**
             * Return tabs of form
             */
            tabs() {
                return this.get(this.form, 'tabs');
            },

        },

        watch: {
            /**
             * Watch model and emit
             */
            model() {
                this.$emit('input', this.model);
            },

            /**
             * Watch value and emit
             */
            value() {
                this.model = this.value;
            },
        },

        methods: {

            /**
             * Run the action
             *
             * @param type
             */
            action(type) {
                this.$emit('action', type);
            },

        },

    };
</script>
