<template>
    <div>
        <div
            v-for="locale in locales"
            :key="`${element.id}-base-${locale}`"
            class="localed-inputs"
        >
            <div class="locale-title">
                {{ $t(`general.locales.${locale}`) }}
            </div>

            <y-form-field
                v-model="model.titles[locale]"
                type="text"
                name="title"
                validation="required"
            />
            <y-form-field
                v-model="model.descriptions[locale]"
                type="textarea"
                name="description"
                rows="2"
            />
        </div>
    </div>
</template>

<script>

    import WareTabMixin from '@/modules/market/mixins/WareTabMixin';

    export default {

        name: 'WareTabBase',

        mixins: [WareTabMixin],

        computed: {

            /**
             * Return locales of product
             */
            locales() {
                return this.element.locales;
            },

        },

    };

</script>
